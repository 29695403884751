import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './style.css'
import Home from './views/home'

const App = () => {
  return (
    <Router>
      <div>
        <Route component={Home} exact path="/" />
        {/* Add the route for the privacy policy */}
        <Route path="/pp" component={() => {
          window.location.href = '/YDS_Hocam_privacy_policy.pdf';
          return null; // Return null since we're redirecting
        }} />
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))